//import { Table } from "react-bootstrap";
import Barcode from "react-barcode";
import "./PrintLabAgentBilling.css";
import useUserData from "../../hooks/useUserData";
import moment from "moment";
const PrintLabAgentBilling = ({
  userInfo,
  tests,
  time,
  date,
  selected,
  paymentOptionSelected,
  grandTotal,
  invoiceNo,
  dueAmount,
  moneyReceipt,
}) => {
  const user = useUserData();
  const totalBill = tests.reduce(
    (totalBill, current) => totalBill + Number(current.fee),
    0
  );
  const discountTotal = tests.reduce(
    (totalDiscount, current) =>
      totalDiscount + (Number(current.fee) * Number(current.discount)) / 100,
    0
  );

  return (
    <div className="sales-invoice">
      <div style={{ padding: "60px" }} className="invoice-print">
        {/* <div className="invoice-pharmacy-details d-flex justify-content-center">
          <div className="text-center">
            <h5>Al Shifa Pharmacy</h5>
            <p>Location : Lalbagh</p>
            <p>Tel : 0171238765</p>
            <p>Vat Reg No :534565 </p>
          </div>
        </div> */}
        {/* <div className="row agent-details mb-3">
          <div className="col-6">
            <p>Agent Name : Mr X</p>
            <p>Agent Phone : 01700001234</p>
          </div>
          <div className="col-6 d-flex justify-content-end">
            <div>
              <p>Patient Name : {userInfo?.patient_first_name}</p>
              <p>Patient Phone : {userInfo?.patient_mobile_phone}</p>
            </div>
          </div>
        </div> */}
        {/* <div className="invoice-date d-flex justify-content-between invoice-border-dashed">
          <p>Invoice No : 1000354 </p>
          <p>Date : {new Date().toLocaleDateString("en-GB")} </p>
        </div> */}

        <div className="invoice-pharmacy-details d-flex gap-2 align-items-center justify-content-start">
          <img
            src={user?.organization_logo}
            // src="https://gdsaasbackend.macrohealthplus.org/logo10240861-846c-4d2c-a8b6-aee9020f0337.jpeg"
            alt="logo"
            style={{ width: "80px", height: "80px" }}
          />
          <div className="text-start">
            <h5>{user?.organization_name}</h5>
            <p>
              {user?.organization_address}{" "}
              <span>Tel : {user?.organization_mobile}</span>
            </p>
            <p>(A Computerized Diagnostic and Consultation Centre)</p>
          </div>
        </div>
        <div className="row  mt-3 mb-1">
          <div className="row col-8">
            <div className="col-6 d-flex align-items-center">
              <p className="p-0 m-0">
                Received No:{" "}
                <span>{moneyReceipt?.money_receipt_number || 10001}</span>
              </p>
            </div>
            <div className="col-6 d-flex justify-content-center">
              <h4
                className="text-center py-1 px-4  m-0"
                style={{
                  border: "1px dashed gray",
                  borderRadius: "20px",
                  display: "inline-block",
                  fontSize: "16px",
                }}
              >
                Cash Memo
              </h4>
            </div>
          </div>
          <div className="col-4 d-flex justify-content-end">
            <div className="d-flex justify-content-end align-items-center">
              Date{" "}
              <span style={{ width: "60px", display: "inline-block" }}>
                :
                {moment(moneyReceipt?.created_at || new Date()).format(
                  "DD/MM/YYYY"
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="row" style={{ borderBottom: "1px dashed gray" }}>
          <div className="col-8">
            <p className="m-0 p-0">
              <span
                style={{
                  width: "100px",
                  display: "inline-block",
                  // whiteSpace: "nowrap",
                }}
              >
                Patient Name
              </span>{" "}
              : {userInfo?.fullName || "N/A"}
            </p>
          </div>
          <div className="col-4 d-flex justify-content-end">
            <div className="d-flex justify-content-end">
              Age{" "}
              <span style={{ paddingLeft: "15px", display: "inline-block" }}>
                : {userInfo?.age || 0} Years
              </span>
            </div>
          </div>
          <div className="col-12">
            <p className="m-0 p-0">
              <span
                style={{
                  width: "104px",
                  display: "inline-block",
                  whiteSpace: "nowrap",
                }}
              >
                Prof./Dr.
              </span>
              : {selected?.fullName || "Self"}
            </p>
          </div>
        </div>
        <div className="invoice-item-table">
          <table>
            <tr className="invoice-border-dashed">
              {/* <td>Code</td> */}
              <td>Name</td>
              <td>Rate</td>
              <td
                className="text-end"
                colSpan={5}
                style={
                  {
                    // width: "100px",
                    // backgroundColor: "gray",
                  }
                }
              >
                Total
              </td>
            </tr>
            {tests?.map((item, i) => (
              <tr key={i}>
                {/* <td>{item.code}</td> */}
                <td className="text-start">{item.test_name}</td>
                <td className="text-start">{item.fee}</td>
                <td className="text-end" colSpan={5}>
                  {parseFloat(item.fee).toFixed(2)}
                </td>
              </tr>
            ))}
            <tr className="invoice-border-dashed-top">
              <td rowSpan={4} colSpan={2}>
                {dueAmount > 0 && (
                  <div className=" d-flex justify-content-center align-items-center">
                    <p
                      style={{
                        fontSize: "22px",
                        whiteSpace: "nowrap",
                        margin: "0",
                        padding: "0",
                      }}
                    >
                      Due Amount: {parseFloat(dueAmount).toFixed(2)}
                    </p>
                  </div>
                )}
              </td>
              <td colSpan={3} className="text-end">
                Sub Total :{" "}
              </td>
              <td className="text-end">{parseFloat(totalBill).toFixed(2)} </td>
            </tr>
            <tr>
              <td colSpan={3} className="text-end">
                Discount :{" "}
              </td>
              <td className="text-end">
                {parseFloat(discountTotal).toFixed(2)}
              </td>
            </tr>

            <tr className="invoice-border-dashed-top">
              <td colSpan={3} className="text-end">
                Bill Total :{" "}
              </td>
              <td className="text-end">
                {parseFloat(totalBill - discountTotal).toFixed(2)}{" "}
              </td>
            </tr>
            {dueAmount > 0 && (
              <tr className="invoice-border-dashed-top">
                <td colSpan={3} className="text-end">
                  Due :{" "}
                </td>
                <td className="text-end">
                  {parseFloat(dueAmount).toFixed(2)}{" "}
                </td>
              </tr>
            )}
          </table>
        </div>
        <div className="d-flex invoice-creator justify-content-between mt-4">
          <p>
            Delivery Date: {moment(date).format("DD/MM/YYYY")} -{" "}
            {moment(time, "HH:mm:ss").format("hh A")}
          </p>
          <p>Posted : {user?.name} </p>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-2">
          <Barcode
            displayValue="false"
            lineColor="#333333"
            width={2}
            height={20}
            value={moneyReceipt?.money_receipt_number || 10001}
          />
        </div>
        {/* <div>
          <p className="text-center p-0 m-0">Thank You</p>
          <p className="text-center p-0 m-0">
            Technology Partner Zaimah Technologies Ltd.
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default PrintLabAgentBilling;
