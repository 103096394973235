import React, { useState, useEffect } from 'react';
import '../LabModule.css';
import Swal from 'sweetalert2';
import axios from 'axios';
import MaterialTable from 'material-table';
import { toast } from 'react-toastify';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
function TestName() {
  ///inside componnet render functionality

  const [pageView, setPageView] = useState('view');
  const [editTestName, setEditTestName] = useState({
    test_name: '',
    fee: '',
    test_parameter: '',
  });
  const [allTestGroup, setallTestGroup] = useState([]);
  const [allTestNameRender, setAllTestNameRender] = useState('');

  const [testGroupId, setTestGroupId] = useState('');
  const [editTestGroupId, setEditTestGroupId] = useState('');
  const [editTestCategoryId, setEditTestCategoryId] = useState('');

  const [dependentTestCategory, setDependentTestCategory] = useState([]);
  const [editDependentTestCategory, setEditDependentTestCategory] = useState(
    [],
  );

  const [testCategoryId, setTestCategoryId] = useState('');

  const [allTestNames, setAllTestNames] = useState([]);
  const [allTestSubCategory, setAllTestSubCategory] = useState([]);

  useEffect(() => {
    axios.get(`/new-test-group`).then((res) => {
      if (res.data.status === 200) {
        setallTestGroup(res.data.test_group);
      }
    });
    if (testGroupId) {
      axios.get(`/new-test-categories-by-id/${testGroupId}`).then((res) => {
        if (res.data.status === 200) {
          setDependentTestCategory(res.data.all_cat);
        }
      });
    }

    if (editTestGroupId) {
      axios.get(`/new-test-categories-by-id/${editTestGroupId}`).then((res) => {
        if (res.data.status === 200) {
          setEditDependentTestCategory(res.data.all_cat);
        }
      });
    }
    axios.get(`/new-test-name`).then((res) => {
      if (res.data.status === 200) {
        setAllTestNames(res.data.test_name);
      }
    });
    if (testCategoryId) {
      axios
        .get(`/test-sub-categories-by-catId/${testCategoryId}`)
        .then((res) => {
          if (res.data.status === 200) {
            setAllTestSubCategory(res.data.sub_category);
          }
        });
    }
  }, [testGroupId, allTestNameRender, testCategoryId, editTestGroupId]);

  const [editTestNameId, setEditTestNameId] = useState('');

  const [testParameterArray, setTestParameterArray] = useState();

  const [editTest_name, setEditTest_name] = useState('');

  const [editData, setEditData] = useState('');
  const [editFee, setEditFee] = useState('');

  useEffect(() => {
    if (editTestNameId) {
      axios.get(`/edit-new-test-name/${editTestNameId}`).then((res) => {
        if (res.data.status === 200) {
          setEditTestName(res.data.test_name);
          setEditTest_name(res.data.test_name.test_name);
          setEditData(res.data.test_name);
          setEditFee(res.data.test_name.fee);
          setTestParameterArray(res.data.test_name.test_parameter.split(','));
        }
      });
    }
  }, [editTestNameId]);

  function editTestCategory(e, editId) {
    e.preventDefault();
    setEditTestNameId(editId);
  }

  const deleteallTestName = (e, id) => {
    e.preventDefault();
    const thisClicked = e.currentTarget;
    //  thisClicked.innerText = "Deleting";

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`/delete-new-test-name/${id}`).then((res) => {
          if (res.data.status === 200) {
            // setAllTestNames(res.data.test_name)

            thisClicked.closest('tr').remove();
            //   swal("Success", res.data.message, "success");
          }
        });
        Swal.fire('Deleted!', 'Your data has been deleted.', 'success');
      }
    });
  };

  const editTestNameData = (id) => {
    axios.get(`/edit-new-test-name/${id}`).then((res) => {
      if (res.data.status === 200) {
        setEditTestName(res.data.test_name);
      }
    });
  };

  const columns = [
    {
      title: 'SL',
      field: '',
      render: (row) => <div>{row.tableData.id + 1}</div>,

      width: '40 !important',
    },
    {
      title: 'Test Name',
      field: `test_name`,
      // render: (row) => <div>{row.test_name}</div>,
    },
    {
      title: 'Parameters',
      field: ``,
      cellStyle: {
        width: '30%',
        textAlign: 'center !important',
      },
      render: (row) => (
        <div>
          {row.parameter === ('' || 'null' || null)
            ? 'No Parameter Available'
            : row.parameter.map((item) => <span>{item.parameter_name}, </span>)}
        </div>
      ),
    },
    {
      title: 'Fee',
      field: ``,

      render: (row) => <div>{row.fee}</div>,
    },
    {
      title: 'Test Group',
      cellStyle: {
        width: '15%',
        textAlign: 'center !important',
      },
      render: (row) => <div>{row.group?.test_group_name}</div>,
    },

    {
      title: 'Test Category',
      field: ``,

      render: (row) => <div>{row.category?.test_category_name}</div>,
    },
    {
      title: 'Action',
      field: 'patient',
      cellStyle: {
        width: '50%',
        textAlign: 'center !important',
      },
      render: (row) => (
        <div
          style={{
            width: '80px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <Link to={`/lab-module-new/edit-lab-test/${row.id}`}>
            <button
              style={{
                all: 'unset',
                cursor: 'pointer',
                fontSize: '13px',
                color: 'black',
              }}
            >
              {' '}
              <i className='far fa-edit'></i>{' '}
            </button>
          </Link>

          {row.parameter.length > 0 && (
            <Link to={`/lab-module-new/add-lab-parameters/${row.id}`}>
              <button
                style={{
                  all: 'unset',
                  cursor: 'pointer',
                  fontSize: '13px',
                  color: 'black',
                }}
              >
                <i className='far fa-arrow-right'></i>
              </button>
            </Link>
          )}

          <button
            onClick={(e) => deleteallTestName(e, row.id)}
            style={{
              all: 'unset',
              cursor: 'pointer',
              fontSize: '13px',
            }}
          >
            {' '}
            <i className='far fa-trash'></i>{' '}
          </button>
        </div>
      ),
      cellStyle: {
        textAlign: 'center',
        width: '250',
      },
    },
  ];

  //add modal

  const [testParameterName, setParameterName] = useState('');
  console.log('Params name', testParameterName);

  const [obj1, setObj1] = useState({
    gender: '',
    minimum_age: '',
    maximum_age: '',
    lower_value: '',
    upper_value: '',
    in_words: '',
  });

  function obj1Change(e) {
    setObj1({
      ...obj1,
      [e.target.name]: e.target.value,
    });
  }

  const [obj2, setObj2] = useState({
    gender: '',
    minimum_age: '',
    maximum_age: '',
    lower_value: '',
    upper_value: '',
    in_words: '',
  });

  function obj2Change(e) {
    setObj2({
      ...obj2,
      [e.target.name]: e.target.value,
    });
  }

  const [obj3, setObj3] = useState({
    gender: '',
    minimum_age: '',
    maximum_age: '',
    lower_value: '',
    upper_value: '',
    in_words: '',
  });

  function obj3Change(e) {
    setObj3({
      ...obj3,
      [e.target.name]: e.target.value,
    });
  }

  const myArray = [obj1, obj2, obj3];

  const saveParameter = () => {
    myArray.map((itm) => {
      const data = {};
      data.test_name_id = editTestNameId;
      data.parameter = testParameterName;
      data.gender = itm.gender;
      data.minimum_age = itm.minimum_age;
      data.maximum_age = itm.maximum_age;
      data.lower_value = itm.lower_value;
      data.upper_value = itm.upper_value;
      data.in_words = itm.in_words;
      console.log('item', data);

      axios.post(`/save-test-parameter`, data).then((res) => {
        if (res.data.status === 200) {
          toast.success(`${testParameterName} parameter added successfully`);
          setEditTestNameId('');
          setParameterName('');
          setObj1({
            gender: '',
            minimum_age: '',
            maximum_age: '',
            lower_value: '',
            upper_value: '',
            in_words: '',
          });

          setObj2({
            gender: '',
            minimum_age: '',
            maximum_age: '',
            lower_value: '',
            upper_value: '',
            in_words: '',
          });

          setObj3({
            gender: '',
            minimum_age: '',
            maximum_age: '',
            lower_value: '',
            upper_value: '',
            in_words: '',
          });
        }
      });
    });
  };

  return (
    <div className='row'>
      <Link to='/lab-module-new/add-lab-test'>
        <button type='submit' className='btns float-end mt-2 '>
          Add New
        </button>
      </Link>

      <div className='col-md-12'>
        <h6 className='px-2'>Test Names</h6>
        <div
          style={{
            overflowX: 'auto',
          }}
        >
          <MaterialTable
            columns={columns}
            data={allTestNames}
            options={{
              search: true,
              showTitle: false,
              searchFieldAlignment: 'left',
              pageSize: 20,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [5, 10, 20, 50, 100],
            }}
          />
        </div>
      </div>

      {/* {parameter page} */}
      {pageView === 'parameter' && (
        <>
          <div className='shadow-sm p-2 mb-3 bg-body rounded mt-1'>
            <h6 className='mt-1 mx-2'>Normal value with Numeric Range</h6>
            <hr />
            <div className='d-flex '>
              <div className='col-md-4'>
                {/* <label for="exampleFormControlInput1" className="form-label">Test Group Name</label> */}
                <Autocomplete
                  disablePortal
                  id='combo-box-demo'
                  size='small'
                  options={testParameterArray}
                  // getOptionLabel={(option) => testParameterArray}
                  onChange={(e, newValue) => {
                    console.log('sad', newValue);
                    setParameterName(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label='Test Parameter' />
                  )}
                />
              </div>
            </div>

            <div className=''>
              <div className='row'>
                <div className='col-md-12 d-flex mt-4'>
                  <div className='mb-3 mt-3 col-2'>
                    <label
                      for='exampleFormControlInput1'
                      className='form-label'
                    >
                      <b>Gender </b>
                    </label>
                    <select
                      className='form-select'
                      aria-label='Default select example'
                      name='gender'
                      value={obj1.gender}
                      onChange={obj1Change}
                    >
                      <option selected>Select</option>
                      <option value='Male'>Male</option>
                      <option value='Female'>Female</option>
                      <option value='Child'>Child</option>
                    </select>
                  </div>

                  <div className='mb-3 mt-3 col-2'>
                    <label
                      for='exampleFormControlInput1'
                      className='form-label'
                    >
                      <b>Minimum Age</b>{' '}
                    </label>
                    <select
                      className='form-select'
                      aria-label='Default select example'
                      name='minimum_age'
                      value={obj1.minimum_age}
                      onChange={obj1Change}
                    >
                      <option selected>Select</option>
                      <option value='0'>0</option>
                      <option value='1'>1</option>
                      <option value='2'>2</option>
                      <option value='3'>3</option>
                      <option value='4'>4</option>
                      <option value='5'>5</option>
                      <option value='6'>6</option>
                      <option value='7'>7</option>
                      <option value='8'>8</option>
                      <option value='9'>9</option>
                      <option value='10'>10</option>
                    </select>
                  </div>

                  <div className='mb-3 mt-3 col-2'>
                    <label
                      for='exampleFormControlInput1'
                      className='form-label'
                    >
                      <b>Maximum Age</b>{' '}
                    </label>
                    <select
                      className='form-select'
                      aria-label='Default select example'
                      name='maximum_age'
                      value={obj1.maximum_age}
                      onChange={obj1Change}
                    >
                      <option selected>Select</option>
                      <option value='0'>0</option>
                      <option value='1'>1</option>
                      <option value='2'>2</option>
                      <option value='3'>3</option>
                      <option value='4'>4</option>
                      <option value='5'>5</option>
                      <option value='6'>6</option>
                      <option value='7'>7</option>
                      <option value='8'>8</option>
                      <option value='9'>9</option>
                      <option value='10'>10</option>
                    </select>
                  </div>

                  <div className='mb-3 mt-3 col-2'>
                    <label
                      for='exampleFormControlInput1'
                      className='form-label'
                    >
                      <b> Lower Value</b>
                    </label>
                    <input
                      type='text'
                      className='form-control mt-1'
                      id='exampleFormControlInput1'
                      name='lower_value'
                      value={obj1.lower_value}
                      onChange={obj1Change}
                    />
                  </div>

                  <div className='mb-3 mt-3 col-2'>
                    <label
                      for='exampleFormControlInput1'
                      className='form-label'
                    >
                      <b>Upper Value </b>
                    </label>
                    <input
                      type='text'
                      className='form-control mt-1'
                      id='exampleFormControlInput1'
                      name='upper_value'
                      value={obj1.upper_value}
                      onChange={obj1Change}
                    />
                  </div>

                  <div className='mb-3 mt-3 col-2'>
                    <label
                      for='exampleFormControlTextarea1'
                      className='form-label'
                    >
                      <b>In words </b>
                    </label>
                    <textarea
                      className='form-control'
                      id='exampleFormControlTextarea1'
                      rows='2'
                      name='in_words'
                      value={obj1.in_words}
                      onChange={obj1Change}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12 d-flex '>
                  <div className='mb-3 mt-3 col-2'>
                    <label
                      for='exampleFormControlInput1'
                      className='form-label'
                    >
                      <b>Gender </b>
                    </label>
                    <select
                      className='form-select'
                      aria-label='Default select example'
                      name='gender'
                      value={obj2.gender}
                      onChange={obj2Change}
                    >
                      <option selected>Select</option>
                      <option value='Male'>Male</option>
                      <option value='Female'>Female</option>
                      <option value='Child'>Child</option>
                    </select>
                  </div>

                  <div className='mb-3 mt-3 col-2'>
                    <label
                      for='exampleFormControlInput1'
                      className='form-label'
                    >
                      <b>Minimum Age</b>{' '}
                    </label>
                    <select
                      className='form-select'
                      aria-label='Default select example'
                      name='minimum_age'
                      value={obj2.minimum_age}
                      onChange={obj2Change}
                    >
                      <option selected>Select</option>
                      <option value='0'>0</option>
                      <option value='1'>1</option>
                      <option value='2'>2</option>
                      <option value='3'>3</option>
                      <option value='4'>4</option>
                      <option value='5'>5</option>
                      <option value='6'>6</option>
                      <option value='7'>7</option>
                      <option value='8'>8</option>
                      <option value='9'>9</option>
                      <option value='10'>10</option>
                    </select>
                  </div>

                  <div className='mb-3 mt-3 col-2'>
                    <label
                      for='exampleFormControlInput1'
                      className='form-label'
                    >
                      <b>Maximum Age</b>{' '}
                    </label>
                    <select
                      className='form-select'
                      aria-label='Default select example'
                      name='maximum_age'
                      value={obj2.maximum_age}
                      onChange={obj2Change}
                    >
                      <option selected>Select</option>
                      <option value='0'>0</option>
                      <option value='1'>1</option>
                      <option value='2'>2</option>
                      <option value='3'>3</option>
                      <option value='4'>4</option>
                      <option value='5'>5</option>
                      <option value='6'>6</option>
                      <option value='7'>7</option>
                      <option value='8'>8</option>
                      <option value='9'>9</option>
                      <option value='10'>10</option>
                    </select>
                  </div>

                  <div className='mb-3 mt-3 col-2'>
                    <label
                      for='exampleFormControlInput1'
                      className='form-label'
                    >
                      <b> Lower Value</b>
                    </label>
                    <input
                      type='text'
                      className='form-control mt-1'
                      id='exampleFormControlInput1'
                      name='lower_value'
                      value={obj2.lower_value}
                      onChange={obj2Change}
                    />
                  </div>

                  <div className='mb-3 mt-3 col-2'>
                    <label
                      for='exampleFormControlInput1'
                      className='form-label'
                    >
                      <b>Upper Value </b>
                    </label>
                    <input
                      type='text'
                      className='form-control mt-1'
                      id='exampleFormControlInput1'
                      name='upper_value'
                      value={obj2.upper_value}
                      onChange={obj2Change}
                    />
                  </div>

                  <div className='mb-3 mt-3 col-2'>
                    <label
                      for='exampleFormControlTextarea1'
                      className='form-label'
                    >
                      <b>In words </b>
                    </label>
                    <textarea
                      className='form-control'
                      id='exampleFormControlTextarea1'
                      rows='2'
                      name='in_words'
                      value={obj2.in_words}
                      onChange={obj2Change}
                    ></textarea>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-12 d-flex '>
                  <div className='mb-3 mt-3 col-2'>
                    <label
                      for='exampleFormControlInput1'
                      className='form-label'
                    >
                      <b>Gender </b>
                    </label>
                    <select
                      className='form-select'
                      aria-label='Default select example'
                      name='gender'
                      value={obj3.gender}
                      onChange={obj3Change}
                    >
                      <option selected>Select</option>
                      <option value='Male'>Male</option>
                      <option value='Female'>Female</option>
                      <option value='Child'>Child</option>
                    </select>
                  </div>

                  <div className='mb-3 mt-3 col-2'>
                    <label
                      for='exampleFormControlInput1'
                      className='form-label'
                    >
                      <b>Minimum Age</b>{' '}
                    </label>
                    <select
                      className='form-select'
                      aria-label='Default select example'
                      name='minimum_age'
                      value={obj3.minimum_age}
                      onChange={obj3Change}
                    >
                      <option selected>Select</option>
                      <option value='0'>0</option>
                      <option value='1'>1</option>
                      <option value='2'>2</option>
                      <option value='3'>3</option>
                      <option value='4'>4</option>
                      <option value='5'>5</option>
                      <option value='6'>6</option>
                      <option value='7'>7</option>
                      <option value='8'>8</option>
                      <option value='9'>9</option>
                      <option value='10'>10</option>
                    </select>
                  </div>

                  <div className='mb-3 mt-3 col-2'>
                    <label
                      for='exampleFormControlInput1'
                      className='form-label'
                    >
                      <b>Maximum Age</b>{' '}
                    </label>
                    <select
                      className='form-select'
                      aria-label='Default select example'
                      name='maximum_age'
                      value={obj3.maximum_age}
                      onChange={obj3Change}
                    >
                      <option selected>Select</option>
                      <option value='0'>0</option>
                      <option value='1'>1</option>
                      <option value='2'>2</option>
                      <option value='3'>3</option>
                      <option value='4'>4</option>
                      <option value='5'>5</option>
                      <option value='6'>6</option>
                      <option value='7'>7</option>
                      <option value='8'>8</option>
                      <option value='9'>9</option>
                      <option value='10'>10</option>
                    </select>
                  </div>

                  <div className='mb-3 mt-3 col-2'>
                    <label
                      for='exampleFormControlInput1'
                      className='form-label'
                    >
                      <b> Lower Value</b>
                    </label>
                    <input
                      type='text'
                      className='form-control mt-1'
                      id='exampleFormControlInput1'
                      name='lower_value'
                      value={obj3.lower_value}
                      onChange={obj3Change}
                    />
                  </div>

                  <div className='mb-3 mt-3 col-2'>
                    <label
                      for='exampleFormControlInput1'
                      className='form-label'
                    >
                      <b>Upper Value </b>
                    </label>
                    <input
                      type='text'
                      className='form-control mt-1'
                      id='exampleFormControlInput1'
                      name='upper_value'
                      value={obj3.upper_value}
                      onChange={obj3Change}
                    />
                  </div>

                  <div className='mb-3 mt-3 col-2'>
                    <label
                      for='exampleFormControlTextarea1'
                      className='form-label'
                    >
                      <b>In words </b>
                    </label>
                    <textarea
                      className='form-control'
                      id='exampleFormControlTextarea1'
                      rows='2'
                      name='in_words'
                      value={obj3.in_words}
                      onChange={obj3Change}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {pageView === 'parameter' && (
            <div>
              <button
                type='submit'
                className='btns float-start'
                onClick={(e) => saveParameter()}
              >
                Update
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default TestName;
