import React, { useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import MaterialTable, { MTableToolbar } from "material-table";
import { AiFillPrinter } from "react-icons/ai";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import Barcode from "react-barcode";
import useUserData from "../../hooks/useUserData";
import useCredentialURL from "../../hooks/useCredentialURL";
import { getAllBranch } from "../../utils/getAllBranch";
import SimpleSelect from "../../common/components/SimpleSelect";
import CountUp from "react-countup";
import { VscPreview } from "react-icons/vsc";
import { nullParser } from "../../utils/null-parser";
import { NewModal } from "../../common/components/NewModal";
import moment from "moment";
import TablePagination from "@mui/material/TablePagination";
import Pagination from "../../common/components/Pagination";
const GreatLabMoneyReciept = () => {
  const [loading, setLoading] = useState(true);
  const [totalInvoice, setTotalInvoice] = useState([]);
  const [totalInvoiceSort, setTotalInvoiceSort] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [previewModal, setPreviewModal] = useState(false);
  const user = useUserData();
  const { SaasAuthURL } = useCredentialURL();
  const [orgBranch, setOrgBranch] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    const getBranch = async () => {
      const branches = await getAllBranch(
        SaasAuthURL + "/branch/service/find-branch-by-organizationId"
      );
      if (branches.status === 200) {
        const updatedBranches = branches?.data?.data?.map((branch) => ({
          ...branch,
          value: branch.id,
          label: branch.name,
        }));
        setOrgBranch(updatedBranches);
      }
    };
    getBranch();
    return () => {};
  }, [SaasAuthURL]);

  // console.log("user", user);
  useEffect(() => {
    if (selectedBranch) {
      const filterData = totalInvoice.filter((item) => {
        return item.saas_branch_id === selectedBranch?.value;
      });
      setTotalInvoiceSort(filterData);
    } else {
      setTotalInvoiceSort(totalInvoice);
    }
    return () => {};
  }, [selectedBranch, totalInvoice]);

  const handleChangeBranch = (data) => {
    if (data) {
      setSelectedBranch(data);
    } else {
      setSelectedBranch(null);
    }
  };

  useEffect(() => {
    axios.get(`/great-lab-all-invoice`).then((res) => {
      if (res.data.status === 200) {
        setTotalInvoice(res.data.invoice);
        setTotalInvoiceSort(res.data.invoice);
        setLoading(false);
      }
    });
  }, []);

  const handleInvoicePreview = (data) => {
    setSelectedInvoice(data);
    setPreviewModal(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = [
    {
      title: "Invoice",
      field: `invoiceNo`,

      cellStyle: {
        width: "2%",
      },
    },
    {
      title: "Name",
      field: `patient_first_name`,
    },
    {
      title: "Branch",
      field: `saas_branch_name`,
    },
    {
      title: "Total Amount",
      field: `totalBill`,
      headerStyle: {
        whiteSpace: "nowrap",
      },
    },
    {
      title: "Paid Amount",
      field: `paidAmount`,
      headerStyle: {
        whiteSpace: "nowrap",
      },
    },
    {
      title: "Due",
      field: `due`,
    },
    {
      title: "Discount",
      field: `discount`,
      render: (row) => {
        const discount = row?.tests?.reduce(
          (total, current) => total + Number(current.discount),
          0
        );
        return `${nullParser(discount) ? discount : ""}`;
      },
    },
    {
      title: "Payment Method",
      field: "paymentMethod",
      headerStyle: {
        whiteSpace: "nowrap",
      },
    },
    {
      title: "Action",
      field: "patient",
      render: (row) => (
        <div className="d-flex align-items-center  gap-2  justify-content-center">
          <button
            data-bs-toggle="tooltip"
            title="Print Invoice"
            onClick={() => handleInvoice(row)}
            className={`new-action-btn `}
          >
            {" "}
            <AiFillPrinter />{" "}
          </button>
          <button
            data-bs-toggle="tooltip"
            title="Print Invoice"
            onClick={() => handleInvoicePreview(row)}
            className={`new-action-btn `}
          >
            {" "}
            <VscPreview />{" "}
          </button>
          &nbsp;
        </div>
      ),
      cellStyle: {
        textAlign: "center",
      },
    },
  ];
  // search with date range
  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
  });
  const searchByDateRange = () => {
    if (dateRange?.startDate && dateRange.endDate) {
      axios
        .post("great-lab-invoice-date-range-search", dateRange)
        .then((res) => {
          if (res.status === 200) {
            setTotalInvoice(res.data.invoice);
            setTotalInvoiceSort(res.data.invoice);
          }
        });
    } else {
      toast.error("Please enter start and end date!");
    }
  };
  // print test List
  const [selectedTest, setSelectedTest] = useState([]);
  // const testRef = useRef();

  const [moneyReceiptData, setMoneyReceiptData] = useState({});
  const invoiceRef = useRef();
  const handleInvoicePrint = useReactToPrint({
    content: () => invoiceRef.current,
  });
  const handleInvoice = (item) => {
    setMoneyReceiptData(item);
    setTimeout(() => handleInvoicePrint(), 1000);
  };
  const totalBill = moneyReceiptData?.tests?.reduce(
    (total, current) => total + Number(current.fee),
    0
  );
  const discountTotal = moneyReceiptData?.tests?.reduce(
    (total, current) => total + Number(current.discount),
    0
  );
  const handleDue = () => {
    const filtered = totalInvoice.filter((item) => Number(item.due) > 0);
    setTotalInvoiceSort(filtered);
  };
  const handlePaid = () => {
    const filtered = totalInvoice.filter((item) => Number(item.paidAmount) > 0);
    setTotalInvoiceSort(filtered);
  };

  const filterColumns = useMemo(() => {
    if (user?.isSuperAdmin) {
      return columns;
    } else {
      return columns.filter((item) => item.field !== "saas_branch_name");
    }
  }, [columns, user?.isSuperAdmin]);

  const handleCloseModal = () => {
    setPreviewModal(false);
    setSelectedInvoice(null);
  };

  const selectedMoneyReceipt = moneyReceiptData;
  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (data) => {
    setLimit(parseInt(data));
    setPage(1);
  };
  console.log("page", page);
  console.log("limit", limit);

  return (
    <div className="ms-2 lab-agent all-patients mt-2">
      <div className="row">
        <div className="col-md-12">
          <div className="patients-head custom-card d-flex justify-content-between align-items-center">
            <h5 className="fw-normal ml-3 text-start mb-1 text-login py-2">
              Great Lab
            </h5>
            {user?.isSuperAdmin && (
              <SimpleSelect
                options={orgBranch}
                value={selectedBranch}
                placeholder="Select Branch"
                onChange={handleChangeBranch}
              />
            )}
          </div>
          <div className="custom-card p-2 mt-2">
            <div className="row">
              <div className="col-3 d-flex align-items-center">
                <h6 className="my-0">Invoice List</h6>
              </div>
              <div
                className="col-9 d-flex justify-content-end align-items-center"
                style={{ gap: "15px" }}
              >
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "10px" }}
                >
                  <label className="fw-bold my-0"> Date</label>
                  <input
                    onChange={(e) =>
                      setDateRange({ ...dateRange, startDate: e.target.value })
                    }
                    type="date"
                    className="form-control form-control-sm"
                  />
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "10px" }}
                >
                  <label className="fw-bold my-0"> To</label>
                  <input
                    onChange={(e) =>
                      setDateRange({ ...dateRange, endDate: e.target.value })
                    }
                    type="date"
                    className="form-control form-control-sm"
                  />
                </div>
                <div className="rx-one-button-group">
                  <button onClick={searchByDateRange} className="btn">
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="mx- my-2">
            <div className="row">
              <div onClick={handlePaid} className="col-4">
                <div className="transaction-card tran__card_1 p-4">
                  <div className="d-flex">
                    <i className="fa-solid fa-file-invoice me-2"></i>
                    <h6>Total Invoice : {totalInvoice.length}</h6>
                  </div>
                  <hr />
                  <p style={{ fontWeight: "500", marginBottom: "0rem" }}>
                    {" "}
                    <span style={{ fontSize: "22px" }} className="me-2">
                      {" "}
                      &#2547;
                    </span>
                    <CountUp
                      start={0}
                      end={
                        totalInvoiceSort.reduce(
                          (total, current) => total + Number(current.totalBill),
                          0
                        ) || 0
                      }
                      duration={2}
                    />
                  </p>
                </div>
              </div>
              <div onClick={handlePaid} className="col-4">
                <div className=" transaction-card tran__card_2 p-4">
                  <div className="d-flex">
                    <i className="fa-solid fa-file-invoice me-2"></i>
                    <h6>Total Received</h6>
                  </div>
                  <hr />
                  <p style={{ fontWeight: "500", marginBottom: "0rem" }}>
                    {" "}
                    <span style={{ fontSize: "22px" }} className="me-2">
                      {" "}
                      &#2547;
                    </span>
                    <CountUp
                      start={0}
                      end={
                        totalInvoiceSort.reduce(
                          (total, current) =>
                            total + Number(current.paidAmount),
                          0
                        ) || 0
                      }
                      duration={2}
                    />
                  </p>
                </div>
              </div>
              <div onClick={handleDue} className="col-4">
                <div className="transaction-card tran__card_3 p-4">
                  <div className="d-flex">
                    <i className="fa-solid fa-file-invoice me-2"></i>
                    <h6>Total Due</h6>
                  </div>
                  <hr />
                  <p style={{ fontWeight: "500", marginBottom: "0rem" }}>
                    {" "}
                    <span style={{ fontSize: "22px" }} className="me-2">
                      {" "}
                      &#2547;
                    </span>
                    <CountUp
                      start={0}
                      end={
                        totalInvoiceSort.reduce(
                          (total, current) => total + Number(current.due),
                          0
                        ) || 0
                      }
                      duration={2}
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="patient-table mt-2">
            <MaterialTable
              columns={filterColumns}
              data={totalInvoiceSort}
              isLoading={loading === true ? true : false}
              options={{
                search: true,
                showTitle: false,
                searchFieldAlignment: "left",
                pageSize: 10,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [5, 10, 20, 50, 100],
              }}
              components={{
                Toolbar: (props) => (
                  <div>
                    <MTableToolbar
                      style={{ width: 80, fontSize: 14 }}
                      {...props}
                    />
                  </div>
                ),
                // Pagination: (props) => (
                //   <div>
                //     <div className="d-flex justify-content-end">
                //       <Pagination
                //         limit={limit}
                //         onChangePerPage={handleChangeRowsPerPage}
                //         onPageChange={handleChangePage}
                //         totalCount={500}
                //         page={page}
                //       />
                //     </div>
                //   </div>
                // ),
              }}
              onSelectionChange={(rows) => setSelectedTest(rows)}
            />
            <div className="mt-2">
              <span className="me-2">
                {" "}
                <i
                  style={{ color: "red" }}
                  className="fa-solid fa-square me-1"
                ></i>{" "}
                Sample Not Collected Yet
              </span>
              <span className="me-2">
                {" "}
                <i
                  style={{ color: "#FFD600" }}
                  className="fa-solid fa-square me-1"
                ></i>{" "}
                Partial Sample Collected
              </span>
              <span className="me-2">
                {" "}
                <i
                  style={{ color: "#69B128" }}
                  className="fa-solid fa-square me-1"
                ></i>{" "}
                Sample Collected Done
              </span>
            </div>
          </div>
        </div>
      </div>

      {selectedTest.length > 0 && (
        <div className="print-money-receipt">
          <div
            ref={invoiceRef}
            className="money-receipt-container px-4 py-2 mt-4"
          >
            <span className="money-receipt-head">
              Online Payment Confirmation Or Offline Payment Confirmation
            </span>
            <div className="receipt-welcome-section mt-3">
              <p>Dear Sir / Madam</p>
              <p>Thank You</p>
              <p>For your payment for medical expense</p>
            </div>
            <div className="mt-3 receipt-pay-info">
              <h6>Payment Confirmation</h6>
              <hr />
              <p>
                <span className="d-inline-block" style={{ width: "200px" }}>
                  Money Receipt Number
                </span>{" "}
                :{" "}
                <span className="receipt-details-value">
                  {moneyReceiptData?.money_receipt_number}
                </span>
              </p>
              <p>
                <span className="d-inline-block" style={{ width: "200px" }}>
                  HN
                </span>{" "}
                :{" "}
                <span className="receipt-details-value">
                  {moneyReceiptData?.hn_number}
                </span>
              </p>
              <p>
                <span className="d-inline-block" style={{ width: "200px" }}>
                  Name
                </span>{" "}
                :{" "}
                <span className="receipt-details-value">
                  {moneyReceiptData?.name}
                </span>
              </p>
              <p>
                <span className="d-inline-block" style={{ width: "200px" }}>
                  Invoice Number
                </span>{" "}
                :{" "}
                <span className="receipt-details-value">
                  {moneyReceiptData?.invoice_number}
                </span>
              </p>
              <p>
                <span className="d-inline-block" style={{ width: "200px" }}>
                  Requested Amount
                </span>{" "}
                :{" "}
                <span className="receipt-details-value">
                  {moneyReceiptData?.requested_amount}
                </span>
              </p>
              <p>
                <span className="d-inline-block" style={{ width: "200px" }}>
                  Amount Paid
                </span>{" "}
                :{" "}
                <span className="receipt-details-value">
                  {moneyReceiptData?.paid_amount}
                </span>
              </p>
              <p>
                <span className="d-inline-block" style={{ width: "200px" }}>
                  Payment Date
                </span>{" "}
                :{" "}
                <span className="receipt-details-value">
                  {moneyReceiptData?.payment_date}
                </span>
              </p>
              <p>
                <span className="d-inline-block" style={{ width: "200px" }}>
                  Payment Time
                </span>{" "}
                :{" "}
                <span className="receipt-details-value">
                  {moneyReceiptData?.payment_time}
                </span>
              </p>
              <p>
                <span className="d-inline-block" style={{ width: "200px" }}>
                  Channel
                </span>{" "}
                :{" "}
                <span className="receipt-details-value">
                  {moneyReceiptData?.payment_method}
                </span>
              </p>
              <p>
                <span className="d-inline-block" style={{ width: "200px" }}>
                  Total Amount Paid
                </span>{" "}
                :{" "}
                <span className="receipt-details-value">
                  {moneyReceiptData?.total_amount_paid}
                </span>
              </p>
            </div>
            <div className="my-3">
              <p>
                If you have any questions or need help, please 'Reply all' to
                this email and we will reply as quickly as possible{" "}
              </p>
            </div>
            <div>
              <p>Sincerely,</p>
              <p>Al-Shifa Hospital</p>
              <p>Phone +66 2066 8888</p>
            </div>
            <div className="mt-5">
              <span className="bottom-nb">
                This e-mail message (and attachments) may contain information
                that is confidential to Bumiungrad International. If you are not
                the intended recipient you cannot use, dismbute or copy the
                message or attachments, In such a case, please nobfy the sender
                by return e-mail immedately and erase all copies of the message
                and attachments. Opinions, conclusions and other information in
                this message and attachments that do not relate to the official
                business of Bumningrad International are nether given no'
                endorsed by it.
              </span>
            </div>
            <div className="mt-2">
              <span className="receipt-end-line">
                Save the Environment — Think before you print this e-man
              </span>
            </div>
          </div>
        </div>
      )}

      <div className="print-invoice">
        <div ref={invoiceRef} className="sales-invoice">
          {moneyReceiptData && (
            <div style={{ padding: "60px" }} className="invoice-print">
              <div className="invoice-pharmacy-details d-flex gap-2 align-items-center justify-content-start">
                <img
                  src={user?.organization_logo}
                  // src="https://gdsaasbackend.macrohealthplus.org/logo10240861-846c-4d2c-a8b6-aee9020f0337.jpeg"
                  alt="logo"
                  style={{ width: "80px", height: "80px" }}
                />
                <div className="text-start">
                  <h5>{user?.organization_name}</h5>
                  <p>
                    {user?.organization_address}{" "}
                    <span>Tel : {user?.organization_mobile}</span>
                  </p>
                  <p>(A Computerized Diagnostic and Consultation Centre)</p>
                </div>
              </div>
              <div className="row  mt-3 mb-1">
                <div className="row col-8">
                  <div className="col-6 d-flex align-items-center">
                    <p className="p-0 m-0">
                      Received No:{" "}
                      <span>{selectedMoneyReceipt?.invoiceNo || 10001}</span>
                    </p>
                  </div>
                  <div className="col-6 d-flex justify-content-center">
                    <h4
                      className="text-center py-1 px-4  m-0"
                      style={{
                        border: "1px dashed gray",
                        borderRadius: "20px",
                        display: "inline-block",
                        fontSize: "16px",
                      }}
                    >
                      Cash Memo
                    </h4>
                  </div>
                </div>
                <div className="col-4 d-flex justify-content-end">
                  <div className="d-flex justify-content-end align-items-center">
                    Date{" "}
                    <span style={{ width: "60px", display: "inline-block" }}>
                      :
                      {moment(selectedMoneyReceipt?.created_at).format(
                        "DD/MM/YYYY"
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row" style={{ borderBottom: "1px dashed gray" }}>
                <div className="col-8">
                  <p className="m-0 p-0">
                    <span
                      style={{
                        width: "100px",
                        display: "inline-block",
                        // whiteSpace: "nowrap",
                      }}
                    >
                      Patient Name
                    </span>{" "}
                    : {selectedMoneyReceipt?.patient?.fullName || "N/A"}
                  </p>
                </div>
                <div className="col-4 d-flex justify-content-end">
                  <div className="d-flex justify-content-end">
                    Age{" "}
                    <span
                      style={{ paddingLeft: "15px", display: "inline-block" }}
                    >
                      : {selectedMoneyReceipt?.patient?.age} Years
                    </span>
                  </div>
                </div>
                <div className="col-12">
                  <p className="m-0 p-0">
                    <span
                      style={{
                        width: "104px",
                        display: "inline-block",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Prof./Dr.
                    </span>
                    : {selectedMoneyReceipt?.referredBy}
                  </p>
                </div>
              </div>
              <div className="invoice-item-table">
                <table>
                  <tr className="invoice-border-dashed">
                    <td>Examination</td>
                    <td>Rate</td>
                    <td colSpan={5} className="text-end">
                      Total
                    </td>
                  </tr>
                  {moneyReceiptData?.tests?.map((item, i) => (
                    <tr key={i}>
                      <td className="text-start">{item.testName}</td>
                      <td className="text-start">{item.fee}</td>
                      <td colSpan={5} className="text-end">
                        {parseFloat(item.fee).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                  <tr className="invoice-border-dashed-top">
                    <td rowSpan={5} colSpan={2}>
                      {Number(selectedMoneyReceipt?.due) > 0 && (
                        <div className=" d-flex justify-content-center align-items-center">
                          <p
                            style={{
                              fontSize: "22px",
                              whiteSpace: "nowrap",
                              margin: "0",
                              padding: "0",
                            }}
                          >
                            Due Amount:{" "}
                            {parseFloat(selectedMoneyReceipt?.due).toFixed(2)}
                          </p>
                        </div>
                      )}
                    </td>
                    <td colSpan={3} className="text-end">
                      Sub Total :{" "}
                    </td>
                    <td className="text-end">
                      {parseFloat(moneyReceiptData?.totalBill).toFixed(2)}{" "}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3} className="text-end">
                      Discount :{" "}
                    </td>
                    <td className="text-end">
                      {parseFloat(moneyReceiptData?.discount).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="invoice-border-dashed-top">
                    <td colSpan={3} className="text-end">
                      Bill Total :{" "}
                    </td>
                    <td className="text-end">
                      {" "}
                      {parseFloat(
                        parseFloat(moneyReceiptData?.totalBill) -
                          parseFloat(moneyReceiptData?.discount)
                      ).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="invoice-border-dashed-top">
                    <td colSpan={3} className="text-end">
                      Paid :{" "}
                    </td>
                    <td className="text-end">
                      {parseFloat(selectedMoneyReceipt?.paidAmount).toFixed(2)}{" "}
                    </td>
                  </tr>
                  {Number(selectedMoneyReceipt?.due) > 0 && (
                    <tr className="invoice-border-dashed-top">
                      <td colSpan={3} className="text-end">
                        Due :{" "}
                      </td>
                      <td className="text-end">
                        {parseFloat(selectedMoneyReceipt?.due).toFixed(2)}{" "}
                      </td>
                    </tr>
                  )}
                </table>
              </div>
              <br />
              <div className="d-flex invoice-creator justify-content-between mt-4">
                <p>
                  Delivery Date:{" "}
                  {moment(moneyReceiptData?.deliveryDate).format("DD/MM/YYYY")}{" "}
                  -{" "}
                  {moment(moneyReceiptData?.deliveryTime, "HH:mm:ss").format(
                    "hh A"
                  )}
                </p>
                <p>Posted : {user?.name}</p>
              </div>
              <div className="d-flex justify-content-center align-items-center mt-2">
                <Barcode
                  displayValue="false"
                  lineColor="#333333"
                  width={2}
                  height={20}
                  value={selectedMoneyReceipt?.invoiceNo || 10001}
                />
              </div>
            </div>
          )}
          {/* {moneyReceiptData && (
            <div style={{ padding: "60px" }} className="invoice-print">
              <div className="invoice-pharmacy-details d-flex justify-content-center">
                <div className="text-center">
                  <h5>{user?.organization_name}</h5>
                  <p>{user?.organization_address}</p>
                  <p>Tel : {user?.organization_mobile}</p>
                </div>
              </div>
              <div className="row agent-details mb-3">
                <div className="col-6">
                  <p>
                    Prof/Dr. : {moneyReceiptData?.doctor?.fullName || "Self"}
                  </p>
                  <p>Patient Name : {moneyReceiptData?.patient_first_name}</p>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <div>
                    <p>
                      Patient Phone : {moneyReceiptData?.patient_mobile_phone}
                    </p>
                  </div>
                </div>
              </div>
              <div className="invoice-date d-flex justify-content-between invoice-border-dashed">
                <p>Invoice No : {moneyReceiptData?.invoiceNo} </p>
                <p>Date : {new Date().toLocaleDateString("en-GB")} </p>
              </div>
              <div className="invoice-item-table">
                <table>
                  <tr className="invoice-border-dashed">
                    <td>Code</td>
                    <td>Name</td>
                    <td>Rate</td>
                    <td className="text-end">Total</td>
                  </tr>
                  {moneyReceiptData?.tests?.map((item, i) => (
                    <tr key={i}>
                      <td>{item.code}</td>
                      <td className="text-start">{item.testName}</td>
                      <td className="text-start">{item.fee}</td>
                      <td className="text-end">{Number(item.fee)}</td>
                    </tr>
                  ))}
                  <tr className="invoice-border-dashed-top">
                    <td colSpan={3} className="text-end">
                      Sub Total :{" "}
                    </td>
                    <td className="text-end">{moneyReceiptData.totalBill} </td>
                  </tr>
                  <tr>
                    <td colSpan={3} className="text-end">
                      VAT / TAX :{" "}
                    </td>
                    <td className="text-end">0</td>
                  </tr>
                  <tr>
                    <td colSpan={3} className="text-end">
                      Discount :{" "}
                    </td>
                    <td className="text-end">{discountTotal}</td>
                  </tr>

                  <tr className="invoice-border-dashed-top">
                    <td colSpan={3} className="text-end">
                      Bill Total :{" "}
                    </td>
                    <td className="text-end">{totalBill - discountTotal} </td>
                  </tr>
                  <tr className="invoice-border-dashed-top">
                    <td colSpan={3} className="text-end">
                      Paid :{" "}
                    </td>
                    <td className="text-end">
                      {moneyReceiptData?.paidAmount}{" "}
                    </td>
                  </tr>
                  {moneyReceiptData?.due && (
                    <tr className="invoice-border-dashed-top">
                      <td colSpan={3} className="text-end">
                        Due :{" "}
                      </td>
                      <td className="text-end">{moneyReceiptData?.due} </td>
                    </tr>
                  )}
                </table>
              </div>
              <div className="d-flex invoice-creator justify-content-between mt-1">
                <p>Provided By: Cashier</p>
                <p>Time : {new Date().toLocaleTimeString()}</p>
              </div>
              <div className="invoice-greeting d-flex justify-content-center align-items-center">
                <Barcode
                  value={moneyReceiptData?.invoiceNo}
                  options={{
                    width: 2,
                    height: 30,
                  }}
                />
              </div>
              <div className="d-flex justify-content-center branding-section">
                <p>Thank You</p>
              </div>
              <div className="branding-section">
                <p>Technology Partner Zaimah Technologies Ltd.</p>
              </div>
            </div>
          )} */}
        </div>
      </div>

      <GreatLabInvoicePreview
        data={selectedInvoice}
        isOpen={previewModal}
        onClose={handleCloseModal}
      />
    </div>
  );
};

export default GreatLabMoneyReciept;

const GreatLabInvoicePreview = ({ data, onClose, isOpen }) => {
  const invoiceRef = useRef();
  const [loading, setLoading] = useState(false);
  const [moneyReceipts, setMoneyReceipts] = useState([]);
  const [selectedMoneyReceipt, setSelectedMoneyReceipt] = useState(null);
  const user = useUserData();

  const handleMoneyPrint = useReactToPrint({
    content: () => invoiceRef.current,
  });
  const handleMoney = (data) => {
    setSelectedMoneyReceipt(data);
    setTimeout(() => handleMoneyPrint(), 300);
  };

  useEffect(() => {
    if (data) {
      (async () => {
        setLoading(true);
        const res = await axios.get(
          `/great-lab-money-receipt-preview/${data?.id}`
        );
        if (res.data.status === 200) {
          setLoading(false);
          setMoneyReceipts(res?.data?.receipt);
        }
      })();
    }
  }, [data]);

  // console.log("moneyReceipts", data);

  const columns = [
    {
      title: "Money Receipt No",
      field: `money_receipt_number`,
      headerStyle: {
        whiteSpace: "nowrap",
      },
    },
    {
      title: "Invoice No",
      field: `invoice_number`,
    },
    {
      title: "Date & Time",
      render: (row) => moment(row?.created_at).format("DD/MM/YYYY - hh A"),
    },
    {
      title: "Paid Amount",
      field: "paid_amount",
      render: (row) => parseFloat(row?.paid_amount).toFixed(2),
    },
    {
      title: "Due Amount",
      field: "due_amount",
      render: (row) => parseFloat(row?.due_amount).toFixed(2),
    },
    {
      title: "Action",
      field: "payment_method",
      render: (row) => (
        <div className="d-flex align-items-center  gap-2  justify-content-center">
          <button
            data-bs-toggle="tooltip"
            title="Print Invoice"
            onClick={() => handleMoney(row)}
            className={`new-action-btn `}
          >
            {" "}
            <AiFillPrinter />{" "}
          </button>
        </div>
      ),
    },
  ];
  //console.log("moneyReceipts", data);
  return (
    <NewModal isOpen={isOpen} onClose={onClose} size="lg">
      <NewModal.Header onClose={onClose}>
        <NewModal.Title>Invoice Summary</NewModal.Title>
      </NewModal.Header>
      <NewModal.Body>
        <MaterialTable
          title={"Money Receipts"}
          columns={columns}
          data={moneyReceipts}
          isLoading={loading}
        />
        <div className="print-invoice">
          <div ref={invoiceRef} className="sales-invoice">
            {selectedMoneyReceipt && (
              <div style={{ padding: "60px" }} className="invoice-print">
                <div className="invoice-pharmacy-details d-flex gap-2 align-items-center justify-content-start">
                  <img
                    src={user?.organization_logo}
                    // src="https://gdsaasbackend.macrohealthplus.org/logo10240861-846c-4d2c-a8b6-aee9020f0337.jpeg"
                    alt="logo"
                    style={{ width: "80px", height: "80px" }}
                  />
                  <div className="text-start">
                    <h5>{user?.organization_name}</h5>
                    <p>
                      {user?.organization_address}{" "}
                      <span>Tel : {user?.organization_mobile}</span>
                    </p>
                    <p>(A Computerized Diagnostic and Consultation Centre)</p>
                  </div>
                </div>
                <div className="row  mt-3 mb-1">
                  <div className="row col-8">
                    <div className="col-6 d-flex align-items-center">
                      <p className="p-0 m-0">
                        Received No:{" "}
                        <span>
                          {selectedMoneyReceipt?.money_receipt_number || 10001}
                        </span>
                      </p>
                    </div>
                    <div className="col-6 d-flex justify-content-center">
                      <h4
                        className="text-center py-1 px-4  m-0"
                        style={{
                          border: "1px dashed gray",
                          borderRadius: "20px",
                          display: "inline-block",
                          fontSize: "16px",
                        }}
                      >
                        Cash Memo
                      </h4>
                    </div>
                  </div>
                  <div className="col-4 d-flex justify-content-end">
                    <div className="d-flex justify-content-end align-items-center">
                      Date{" "}
                      <span style={{ width: "60px", display: "inline-block" }}>
                        :
                        {moment(selectedMoneyReceipt?.created_at).format(
                          "DD/MM/YYYY"
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ borderBottom: "1px dashed gray" }}
                >
                  <div className="col-8">
                    <p className="m-0 p-0">
                      <span
                        style={{
                          width: "100px",
                          display: "inline-block",
                          // whiteSpace: "nowrap",
                        }}
                      >
                        Patient Name
                      </span>{" "}
                      : {selectedMoneyReceipt?.name}
                    </p>
                  </div>
                  <div className="col-4 d-flex justify-content-end">
                    <div className="d-flex justify-content-end">
                      Age{" "}
                      <span
                        style={{ paddingLeft: "15px", display: "inline-block" }}
                      >
                        : {selectedMoneyReceipt?.age} Years
                      </span>
                    </div>
                  </div>
                  <div className="col-12">
                    <p className="m-0 p-0">
                      <span
                        style={{
                          width: "104px",
                          display: "inline-block",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Prof./Dr.
                      </span>
                      : {selectedMoneyReceipt?.referredBy}
                    </p>
                  </div>
                </div>
                <div className="invoice-item-table">
                  <table>
                    <tr className="invoice-border-dashed">
                      <td>Examination</td>
                      <td>Rate</td>
                      <td colSpan={5} className="text-end">
                        Total
                      </td>
                    </tr>
                    {data?.tests?.map((item, i) => (
                      <tr key={i}>
                        <td className="text-start">{item.testName}</td>
                        <td className="text-start">{item.fee}</td>
                        <td colSpan={5} className="text-end">
                          {parseFloat(item.fee).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                    <tr className="invoice-border-dashed-top">
                      <td rowSpan={5} colSpan={2}>
                        {selectedMoneyReceipt?.due_amount > 0 && (
                          <div className=" d-flex justify-content-center align-items-center">
                            <p
                              style={{
                                fontSize: "22px",
                                whiteSpace: "nowrap",
                                margin: "0",
                                padding: "0",
                              }}
                            >
                              Due Amount:{" "}
                              {parseFloat(
                                selectedMoneyReceipt?.due_amount
                              ).toFixed(2)}
                            </p>
                          </div>
                        )}
                      </td>
                      <td colSpan={3} className="text-end">
                        Sub Total :{" "}
                      </td>
                      <td className="text-end">
                        {parseFloat(data?.totalBill).toFixed(2)}{" "}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3} className="text-end">
                        Discount :{" "}
                      </td>
                      <td className="text-end">
                        {parseFloat(data?.discount).toFixed(2)}
                      </td>
                    </tr>
                    <tr className="invoice-border-dashed-top">
                      <td colSpan={3} className="text-end">
                        Bill Total :{" "}
                      </td>
                      <td className="text-end">
                        {" "}
                        {parseFloat(
                          parseFloat(data?.totalBill) -
                            parseFloat(data?.discount)
                        ).toFixed(2)}
                      </td>
                    </tr>
                    <tr className="invoice-border-dashed-top">
                      <td colSpan={3} className="text-end">
                        Paid :{" "}
                      </td>
                      <td className="text-end">
                        {parseFloat(selectedMoneyReceipt?.paid_amount).toFixed(
                          2
                        )}{" "}
                      </td>
                    </tr>
                    {selectedMoneyReceipt?.due_amount > 0 && (
                      <tr className="invoice-border-dashed-top">
                        <td colSpan={3} className="text-end">
                          Due :{" "}
                        </td>
                        <td className="text-end">
                          {parseFloat(selectedMoneyReceipt?.due_amount).toFixed(
                            2
                          )}{" "}
                        </td>
                      </tr>
                    )}
                  </table>
                </div>
                <br />
                <div className="d-flex invoice-creator justify-content-between mt-4">
                  <p>
                    Delivery Date:{" "}
                    {moment(data?.deliveryDate).format("DD/MM/YYYY")} -{" "}
                    {moment(data?.deliveryTime, "HH:mm:ss").format("hh A")}
                  </p>
                  <p>Posted : {user?.name}</p>
                </div>
                <div className="d-flex justify-content-center align-items-center mt-2">
                  <Barcode
                    displayValue="false"
                    lineColor="#333333"
                    width={2}
                    height={20}
                    value={selectedMoneyReceipt?.money_receipt_number || 10001}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </NewModal.Body>
    </NewModal>
  );
};
